import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { OverviewDataFragment } from "../../../../__generated__/graphql-gatsby"

interface IProps {
  node: Maybe<OverviewDataFragment>
}

const GatsbyLink = styled(Link)`
  display: inline-block;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing(4)}px;
  text-decoration: none;
  width: 100%;
  max-width: 328px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-align: center;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  transform: scale(1);
  transition: ${({ theme }) =>
    theme.transitions.create(["box-shadow", "transform"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    })};

  :hover {
    transform: scale(1.013);
    box-shadow: ${({ theme }) => theme.shadows[8]};
  }
`

const Text = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Card: React.FC<IProps> = ({ node }) => {
  let altText = ""
  let image: any

  if (
    node?.__typename === "WpActiviteit" ||
    node?.__typename === "WpErvaring" ||
    node?.__typename === "WpNieuws" ||
    node?.__typename === "WpVacature"
  ) {
    image =
      node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
    altText = node.featuredImage?.node?.altText
      ? node.featuredImage?.node?.altText
      : ""
  }

  return (
    <GatsbyLink to={node?.uri || "#"}>
      {image && <GatsbyImage image={image} alt={altText} />}
      <Text>
        <Typography variant="h6" component="span">
          {node?.title}
        </Typography>
      </Text>
    </GatsbyLink>
  )
}

export default Card
