import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { OverviewDataFragment } from "../../../../__generated__/graphql-gatsby"

interface IProps {
  node: Maybe<OverviewDataFragment>
}

const TextWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing(5)}px ${theme.spacing(2)}px ${theme.spacing(4)}px`};
  height: 262px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.md}px) {
    height: fit-content;
  }

  &::before {
    content: "";
    background-color: ${({ theme }) => theme.palette.primary.main};
    height: 88px;
    position: absolute;
    left: 0;
    right: 0;
    top: -87px;
    clip-path: polygon(0 56%, 0% 100%, 100% 100%);

    transition: ${({ theme }) =>
      theme.transitions.create("background-color", {
        duration: theme.transitions.duration.complex,
        easing: theme.transitions.easing.easeInOut,
      })};
  }

  .quotes {
    position: absolute;
    top: -56px;
    left: ${({ theme }) => theme.spacing(2)}px;
    font-size: 10rem;
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const GatsbyLink = styled(Link)`
  display: inline-block;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing(4)}px;
  text-decoration: none;
  width: 100%;
  max-width: 442px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  transform: scale(1);
  transition: ${({ theme }) =>
    theme.transitions.create(["color", "background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    })};

  .italic {
    font-style: italic;
  }

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.contrastText};
    color: ${({ theme }) => theme.palette.primary.main};

    ${TextWrapper}::before {
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.md}px) {
    margin: ${({ theme }) => `${theme.spacing(4)}px 0`};
  }
`

const Text: React.FC<IProps> = ({ node }) => {
  let altText = ""
  let image: any
  let excerpt = ""

  if (
    node?.__typename === "WpActiviteit" ||
    node?.__typename === "WpErvaring" ||
    node?.__typename === "WpNieuws" ||
    node?.__typename === "WpVacature"
  ) {
    const maxExcerpt = 382
    const oneLessMax = maxExcerpt - 1
    image =
      node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
    altText = node.featuredImage?.node?.altText
      ? node.featuredImage?.node?.altText
      : ""
    excerpt = String(node.excerpt)

    if (excerpt.length > maxExcerpt) {
      const cut = excerpt.charAt(oneLessMax) === " " ? oneLessMax : maxExcerpt
      excerpt = excerpt.substring(0, cut).concat(`...`)
    }
  }

  return (
    <GatsbyLink to={node?.uri || "#"}>
      {image && <GatsbyImage image={image} alt={altText} />}
      <TextWrapper>
        <span className="quotes">‟</span>
        <Typography
          variant="body2"
          component="div"
          className="italic"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
        <Typography variant="body1" component="div">
          {`Lees het verhaal van ${node?.title}`}
          <span style={{ marginLeft: 10 }}>→</span>
        </Typography>
      </TextWrapper>
    </GatsbyLink>
  )
}

export default Text
