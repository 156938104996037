import React from "react"
import { OverviewArray } from ".."
import Card from "./card"
import Text from "./text"
import Wrapper from "./wrapper"

interface IProps {
  data: OverviewArray
  sessionKey: string
  text?: boolean
}

type GetMaxWidth = (
  text: boolean | undefined,
  data: OverviewArray
) => "xl" | "lg" | "md"

const getMaxWidth: GetMaxWidth = (text, data) => {
  if (text) {
    return "xl"
  } else {
    if (data.length === 4) {
      return "md"
    } else {
      return "lg"
    }
  }
}

const Grid: React.FC<IProps> = ({ data, sessionKey, text }) => {
  const Component = text ? Text : Card
  const maxWidth = getMaxWidth(text, data)

  return (
    <Wrapper
      data={data}
      sessionKey={sessionKey}
      Component={Component}
      maxWidth={maxWidth}
    />
  )
}

export default Grid
